import request from '@/utils/request'

// 登录方法
export function login(username, password, code, uuid) {
  const data = {
    username,
    password,
    code,
    uuid,
  }
  return request({
    url: '/login',
    headers: {
      isToken: false,
    },
    method: 'post',
    data: data,
  })
}
// 数据字典
export function getDicts(query) {
  return request({
    url: '/system/dict/data/getDict',
    params: query,
    method: 'get',
  })
}
// 考试列表
export function getExam(id, subid) {
  return request({
    url: '/exam/exam/getExamOfStu/' + id + '/' + subid,
    method: 'get',
  })
}
// 直播列表
// export function getLives(query) {
//     return request({
//         url: '/living/living/list',
//         method: 'get',
//         params: query
//     })
// }

// 直播列表2
export function getLives(query) {
  return request({
    url: '/playback/list/playList',
    method: 'get',
    params: query,
  })
}
// 课程列表
export function getCourseList(query) {
  return request({
    url: '/stu/sub/getsubListByStuId',
    method: 'get',
    params: query,
  })
}
// 获取试卷信息
export function getExamDetail(data) {
  return request({
    url: `/exam/exam/getStuExamPaperInFo`,
    method: 'post',
    data,
  })
}
export function setStydyTime(query) {
  return request({
    url: '/stu/sub/updStuClass',
    method: 'put',
    data: query,
  })
}
// 课程详情
export function getCourseListDetail(query) {
  return request({
    url: '/stu/sub/getClassBySubId',
    method: 'get',
    params: query,
  })
}
// 获取用户详细信息
export function getInfo(id) {
  return request({
    url: '/student/student/' + id,
    method: 'get',
  })
}

// 退出方法
export function logout() {
  return request({
    url: '/logout',
    method: 'post',
  })
}

// 获取验证码
export function getCodeImg() {
  return request({
    url: '/captchaImage',
    headers: {
      isToken: false,
    },
    method: 'get',
    timeout: 20000,
  })
}
// 证书查询接口
export function queryCertificate(id) {
  return request({
    url: 'exam/exam/queryCertificate/' + id,
    method: 'get',
  })
}
// 证书下载接口
export function downloadCertificate(id) {
  return request({
    url: 'exam/exam/downloadCertificate?certificate=' + id,
    method: 'get',
  })
}
// 证书扫码查询
export function checkCertificate(stuId, paperId) {
  return request({
    url: '/exam/exam/checkCertificate/' + stuId + '/' + paperId,
    method: 'get',
  })
}
// 兑换凭证
export function getPlayCode(idVarchar) {
  return request({
    url: `/system/source/getPlayCode/${idVarchar}`,
    method: 'get',
  })
}
// 获取时间
export function getSecond(time) {
  return request({
    url: `/playback/list/getSecond/${time}`,
    method: 'get',
    // params: {
    //     time: time
    // }
  })
}
export function createPaperByRule(data) {
  return request({
    url: `/exam/exam/createPaperByRule`,
    method: 'post',
    data,
  })
}
export function role(id) {
  return request({
    url: `/role/${id}`,
    method: 'get',
  })
}
export function stuRecordSaveOrUpdate(data) {
  return request({
    url: `/exam/exam/stuRecordSaveOrUpdate`,
    method: 'post',
    data,
  })
}
// 观看视频添加记录
export function watchjoin(data) {
  return request({
    url: `/watch/join`,
    method: 'post',
    data,
  })
}
// 观看视频添加记录
export function watchexit(data) {
  return request({
    url: `/watch/exit`,
    method: 'post',
    data,
  })
}

export function doQualification() {
  return request({
    url: `/watch/qualifications`,
    method: 'get',
  })
}
// 修改用户密码
export function updatePwd(params) {
  return request({
    url: `/system/user/profile/updatePwd`,
    method: 'put',
    params,
  })
}
// 修改个人信息

export function fixstudent(data) {
  return request({
    url: `student/student`,
    method: 'put',
    data,
  })
}
// 获取考试类型

export function getnoPageList() {
  return request({
    url: `/subject/subject/noPageList`,
    method: 'get',
  })
}
