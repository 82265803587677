<template>
  <div class="login_main">
    <div class="login_center">
      <div class="login_center_left">
        <p style="font-size: 30px">
          甘肃省房屋市政工程质量安全监督人员 <br />
          网络培训考核系统
        </p>
        <!-- <p>兰州新区燃气从业人员专业培训考核系统</p> -->
        <!-- <p>
          Lanzhou New area gas practitioners professional training and
          assessment system
        </p> -->
        <img src="../assets/sjcc.png" alt="" srcset="" />
      </div>
      <div class="login_center_right">
        <div class="video_b_right">
          <div class="video_b_right_head">
            <el-tabs>
              <el-tab-pane label="账号登录">
                <!--                  <el-form v-model="formLogin" ref="ruleForm1" :rules="rules1">-->
                <!--                    <el-form-item label="" prop="account">-->
                <!--                      <el-input v-model="formLogin.account" placeholder="请输入您的登录账号"></el-input>-->
                <!--                    </el-form-item>-->
                <!--                    <el-form-item label="" prop="password">-->
                <!--                      <el-input type="password" v-model="formLogin.password" placeholder="请输入您的登陆密码"></el-input>-->
                <!--                    </el-form-item>-->
                <!--                    <el-form-item>-->
                <!--                      <el-checkbox v-model="formLogin.type">记住账号</el-checkbox>-->
                <!--                    </el-form-item>-->
                <!--                    <div class="subClass" @click="sub()">登录</div>-->
                <!--                  </el-form>-->
                <el-form
                  ref="loginForm"
                  :model="loginForm"
                  :rules="loginRules"
                  class="login-form"
                >
                  <el-form-item prop="username">
                    <el-input
                      v-model="loginForm.username"
                      type="text"
                      auto-complete="off"
                      placeholder="账号"
                    >
                      <!--                        <svg-icon slot="prefix" icon-class="user" class="el-input__icon input-icon" />-->
                    </el-input>
                  </el-form-item>
                  <div class="warringtext">
                    若使用身份证登录,最后一位含有X为大写
                  </div>
                  <el-form-item prop="password">
                    <el-input
                      v-model="loginForm.password"
                      type="password"
                      auto-complete="off"
                      placeholder="密码"
                      @keyup.enter.native="handleLogin"
                    >
                      <!--                        <svg-icon slot="prefix" icon-class="password" class="el-input__icon input-icon" />-->
                    </el-input>
                  </el-form-item>
                  <el-form-item prop="code" v-if="captchaEnabled">
                    <el-input
                      v-model="loginForm.code"
                      auto-complete="off"
                      placeholder="验证码"
                      style="width: 63%"
                      @keyup.enter.native="handleLogin"
                    >
                      <!--                        <svg-icon slot="prefix" icon-class="validCode" class="el-input__icon input-icon" />-->
                    </el-input>
                    <div class="login-code">
                      <img
                        :src="codeUrl"
                        @click="getCode"
                        class="login-code-img"
                      />
                    </div>
                  </el-form-item>
                  <el-form-item style="height: 30px">
                    <el-checkbox
                      v-model="loginForm.rememberMe"
                      style="margin: 0px 0px 25px 0px"
                      >记住密码</el-checkbox
                    >
                  </el-form-item>

                  <el-form-item style="width: 100%">
                    <el-button
                      :loading="loading"
                      size="medium"
                      type="primary"
                      @click.native.prevent="handleLogin"
                      class="btn"
                    >
                      <span v-if="!loading">登 录</span>
                      <span v-else>登 录 中...</span>
                    </el-button>
                    <!--  <div style="float: right;" v-if="register">-->
                    <!--  <router-link class="link-type" :to="'/register'">立即注册</router-link>-->
                    <!--  </div>-->
                  </el-form-item>
                </el-form>
              </el-tab-pane>
            </el-tabs>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getCodeImg } from '@/utils/api'
import Cookies from 'js-cookie'
export default {
  name: 'login',
  data() {
    return {
      // formLogin: {
      //   account: '',
      //   password: '',
      //   type: '1'
      //
      // },
      // rules1: {
      //   account: [
      //     {required: true, message: '请输入您的身份证号', trigger: 'blur'},
      //     // { min: 3, max: 5, message: '请输入您的身份证号', trigger: 'blur' }
      //   ],
      //   password: [
      //     {required: true, message: '请输入您的登陆密码', trigger: 'blur'}
      //   ]
      // }
      codeUrl: '',
      loginForm: {
        username: '',
        password: '',
        rememberMe: false,
        code: '',
        uuid: '',
      },
      loginRules: {
        username: [
          {
            required: true,
            trigger: 'blur',
            message: '请输入您的账号',
          },
        ],
        password: [
          { required: true, trigger: 'blur', message: '请输入您的密码' },
        ],
        code: [{ required: true, trigger: 'change', message: '请输入验证码' }],
      },
      loading: false,
      // 验证码开关
      captchaEnabled: true,
    }
  },
  mounted() {
    this.getCode()
    this.getCookie()
  },
  methods: {
    getCode() {
      getCodeImg().then((res) => {
        this.captchaEnabled =
          res.captchaEnabled === undefined ? true : res.captchaEnabled
        if (this.captchaEnabled) {
          this.codeUrl = 'data:image/gif;base64,' + res.img
          this.loginForm.uuid = res.uuid
        }
      })
    },
    getCookie() {
      let that = this
      const username = Cookies.get('username')
      const password = Cookies.get('password')
      const rememberMe = Cookies.get('rememberMe')
      this.loginForm = {
        username: username === undefined ? this.loginForm.username : username,
        password: password === undefined ? this.loginForm.password : password,
        rememberMe: rememberMe === undefined ? false : Boolean(rememberMe),
      }
      console.log('this.loginForm', this.loginForm)
    },
    handleLogin() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          this.loading = true
          if (this.loginForm.rememberMe) {
            Cookies.set('username', this.loginForm.username, { expires: 30 })
            Cookies.set('password', this.loginForm.password, {
              expires: 30,
            })
            Cookies.set('rememberMe', this.loginForm.rememberMe, {
              expires: 30,
            })
          } else {
            Cookies.remove('username')
            Cookies.remove('password')
            Cookies.remove('rememberMe')
          }
          this.$store
            .dispatch('Login', this.loginForm)
            .then(() => {
              // this.$router.push({ path: this.redirect || '/' }).catch(() => {})
              this.$router.push({ path: 'homeview' }).catch(() => {})
            })
            .catch(() => {
              this.loading = false
              if (this.captchaEnabled) {
                this.getCode()
              }
            })
        }
      })
    },
  },
}
</script>

<style scoped lang="scss">
.login_main {
  // position: fixed;
  width: 100%;
  height: 100vh;
  background: url('@/assets/indexbg.png') no-repeat;
  background-size: 100% 100%;
  // top: 0;
  // z-index: -1;
  .login_center {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 1200px;
    height: 414px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .login_center_left {
      width: 740px;
      //height: 279px;
      height: 100%;
      p {
        padding: 0;
        margin: 0;
        font-family: Source Han Serif CN;
        font-weight: 800;
        color: #ffffff;
      }
      :nth-child(1) {
        font-size: 48px;
        margin-bottom: 10px;
      }
      :nth-child(2) {
        font-size: 30px;
        font-family: Source Han Serif CN;
        font-weight: 700;
        color: #ffffff;
        margin-bottom: 10px;
      }
      :nth-child(3) {
        font-size: 15px;
        font-family: Source Han Serif CN;
        font-weight: bold;
        color: #ffffff;
        opacity: 0.9;
      }
      img {
        width: 100%;
        height: 100%;
      }
    }
    .login_center_right {
      width: 484px;
      height: 414px;
      .video_b_right {
        border-radius: 14px;
        max-width: 600px;
        background-color: #ffffff;
        padding: 52px 38px 10px;
        /* position: fixed; */
        /*top: 7%;*/
        /*right: 30px;*/
        width: 484px;
        //height: 475px;
        box-sizing: border-box;
        .subClass {
          width: 258px;
          height: 43px;
          background: #409eff;
          border-radius: 21px;
          text-align: center;
          line-height: 43px;
          font-size: 15px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #ffffff;
          margin: 0 auto;
        }
      }
    }
  }
}

::v-deep .el-form-item__content {
  text-align: left;
}
::v-deep .el-input__inner {
  height: 54px;
  line-height: 54px;
}
::v-deep .el-tabs__header {
  margin: 0 0 30px;
}
::v-deep .el-form-item__content {
  display: flex;
}
::v-deep .login-code {
  line-height: 54px;
  height: 54px;
  img {
    width: 100%;
    height: 100%;
  }
}
.warringtext {
  text-align: left;
  font-size: 12px;
  color: red;
  margin-bottom: 5px;
}
</style>
