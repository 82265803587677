<template>
  <div id="app" ref="app">
    <headmain></headmain>
    <router-view />
    <footmain></footmain>
  </div>
</template>
<script>
import _ from 'lodash'
import headmain from '@/components/head.vue'
import footmain from '@/components/foot.vue'
export default {
  components: {
    headmain,
    footmain,
  },
  // watch: {
  //   // 监听,当路由发生变化的时候执行
  //   $route: {
  //     // $route可以用引号，也可以不用引号
  //     handler(to, from) {
  //       if (this.$route.path == '/login') {
  //         console.log(123555, to)

  //         let dom = document.querySelector('#app')
  //         this.$nextTick(() => {
  //           dom.style.height = '100vh important'
  //         })
  //       } else {
  //         console.log(456, to)
  //         let dom = document.querySelector('#app')
  //         dom.style.height = 'auto'
  //       }
  //     },
  //     deep: true, // 深度观察监听
  //     immediate: true, // 第一次初始化渲染就可以监听到
  //   },
  // },
  // mounted() {
  //
  //   this.$nextTick(() => {
  //     const $app = this.$refs.app
  //     const standardScale = 1080 / 1920
  //
  //     window.addEventListener(
  //         'resize',
  //         _.debounce(function () {
  //           const docHeight = document.body.clientHeight
  //           const docWidth = document.body.clientWidth
  //           if (docWidth < 1920) {
  //             const currentScale = docHeight / docWidth
  //             let [scale, translate] = [0, 0]
  //             if (currentScale < standardScale) {
  //               // 以高度计算
  //               scale = docHeight / 1080
  //               const shouleWidth = 1920 * scale
  //               const offsetWidth = docWidth - shouleWidth
  //               console.log('6666')
  //               translate = offsetWidth > 0 ? `translate(${offsetWidth / 2}px, 0)` : ''
  //             } else {
  //               // 以宽度计算
  //               scale = docWidth / 1920
  //               const shouleHeight = 1080 * scale
  //               const offsetHeight = docHeight - shouleHeight
  //               translate = offsetHeight > 0 ? `translate(0, ${offsetHeight / 2}px)` : ''
  //             }
  //             $app.style.cssText = `
  //           transform: scale(${scale});
  //           transform-origin: top left;
  //           width: 1920px;
  //           min-height: 1080px;
  //         `
  //           } else {
  //             //适配1920以上的分辨率
  //             let [scale, translate] = [0, 0]
  //             // 以宽度计算
  //             scale = docWidth / 1920
  //             const shouleHeight = 1080 * scale
  //             const offsetHeight = docHeight - shouleHeight
  //             translate = offsetHeight > 0 ? `translate(0, ${offsetHeight / 2}px)` : ''
  //             $app.style.cssText = `
  //           transform: scale(${scale});
  //           transform-origin: top left;
  //           width: 1920px;
  //           min-height: 1080px;
  //         `
  //           }
  //         }),
  //         300
  //     )
  //
  //     if (document.createEvent) {
  //       var event = document.createEvent('HTMLEvents')
  //       event.initEvent('resize', true, true)
  //       window.dispatchEvent(event)
  //     } else if (document.createEventObject) {
  //       window.fireEvent('onresize')
  //     }
  //   })
  //
  // },
}
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width: 100%;
  overflow-x: hidden;
  font-family: Microsoft YaHei !important;
  // position: relative;
  background-color: rgb(245, 245, 245);
  // height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
* {
  padding: 0;
  margin: 0;
}
</style>
